export const builder = {
  fa: {
    name: "مهندس علی رضا کردیان",
    about:
      "علیرضا کردیان، بنیان‌گذار و صاحب امتیاز برند «کندو کندی»، یکی از پیشگامان صنعت تولید محصولات ارگانیک در ایران است. او فعالیت‌های خود را از اوایل دهه 60 شمسی آغاز کرد و با تکیه بر دانش و تجربه خود، به تولید محصولاتی با کیفیت و طبیعی پرداخت. کردیان با هدف ارتقاء سلامتی و ترویج مصرف محصولات ارگانیک، به ویژه در زمینه عسل، لواشک و پنیر، گام‌های مؤثری برداشت. او با استفاده از روش‌های سنتی و مدرن، توانسته است محصولاتی با طعم و کیفیت عالی تولید کند که به سرعت در بازار محبوبیت پیدا کردند. برند کندو کندی به عنوان نماد کیفیت و اصالت در تولید محصولات ارگانیک شناخته می‌شود و کردیان با تعهد به اصول پایداری و محیط زیست، همواره در تلاش است تا بهترین‌ها را برای مشتریان خود فراهم کند. تلاش‌های او نه تنها به توسعه صنعت غذایی ایران کمک کرده، بلکه فرهنگ مصرف محصولات ارگانیک را نیز در جامعه ترویج داده است.",
  },
  ar: {
    name: "المهندس علي رضا كوردیان",
    about:
      "علي رضا كوردیان، مؤسس ومالك علامة «كاندو كاندي»، يُعد من رواد صناعة إنتاج المنتجات العضوية في إيران. بدأ نشاطه في أوائل الثمانينات الميلادية، واعتمد على معرفته وخبرته لإنتاج منتجات ذات جودة وطبيعية عالية. قام كوردیان بخطوات هامة لتعزيز الصحة ونشر استهلاك المنتجات العضوية، خصوصاً في مجالات العسل، قمر الدين، والجبن. باستخدامه أساليب تقليدية وحديثة، تمكن من إنتاج منتجات ذات طعم وجودة ممتازة حازت بسرعة على شعبية كبيرة في السوق. تُعتبر علامة «كاندو كاندي» رمزاً للجودة والأصالة في إنتاج المنتجات العضوية، ويعمل كوردیان دائماً على تقديم الأفضل لعملائه مع التزامه بمبادئ الاستدامة وحماية البيئة. جهوده لم تساهم فقط في تطوير صناعة الأغذية في إيران، بل أسهمت أيضاً في نشر ثقافة استهلاك المنتجات العضوية في المجتمع.",
  },
  en: {
    name: "Engineer Ali Reza Kordian",
    about:
      "Ali Reza Kordian, the founder and owner of the 'Kando Candy' brand, is one of the pioneers of organic product manufacturing in Iran. He began his activities in the early 1980s and, relying on his knowledge and experience, started producing high-quality and natural products. Kordian took significant steps to promote health and encourage the consumption of organic products, especially in the fields of honey, fruit leather, and cheese. Using both traditional and modern methods, he has managed to produce products with excellent taste and quality that quickly gained popularity in the market. The 'Kando Candy' brand is recognized as a symbol of quality and authenticity in the production of organic products, and Kordian, committed to sustainability and environmental principles, always strives to provide the best for his customers. His efforts have not only contributed to the development of Iran's food industry but also promoted the culture of consuming organic products in society.",
  },
  ru: {
    name: "Инженер Али Реза Кордиан",
    about:
      "Али Реза Кордиан, основатель и владелец бренда «Кандо Кэнди», является одним из пионеров в производстве органических продуктов в Иране. Он начал свою деятельность в начале 1980-х годов, опираясь на свои знания и опыт, и приступил к производству высококачественных и натуральных продуктов. Кордиан предпринял значительные шаги для улучшения здоровья и популяризации потребления органических продуктов, особенно в области меда, фруктовой пастилы и сыра. Используя как традиционные, так и современные методы, ему удалось создать продукты с отличным вкусом и качеством, которые быстро завоевали популярность на рынке. Бренд «Кандо Кэнди» признан символом качества и подлинности в производстве органических продуктов, а Кордиан, придерживаясь принципов устойчивого развития и заботы об окружающей среде, всегда стремится предоставить своим клиентам лучшее. Его усилия способствовали не только развитию пищевой промышленности Ирана, но и популяризации культуры потребления органических продуктов в обществе.",
  },
};
