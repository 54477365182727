import axios from "axios";

// const token = localStorage.getItem("persist:root")
//   ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
//       .currentUser?.token
//   : "undefined";

export const publicRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

export const adminRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

publicRequest.interceptors.request.use(
  (config) => {
    const tok = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root"))?.user
    )?.currentUser?.token;
    if (tok) {
      const token = tok;
      config.headers["token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

adminRequest.interceptors.request.use(
  (config) => {
    const tok = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root"))?.user
    )?.currentUser?.token;
    if (tok) {
      const token = tok;
      config.headers["token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
